import React from "react"
import Header from './header'
import Footer from './footer'

import 'bootstrap/dist/css/bootstrap.min.css' 
import { container, content, footer } from './layout.module.scss'
import '../../styles/global.scss'


const Layout = (props) => {
    return (
        <div className={container}>
            <div className={content}>
                <Header />
                { props.children }
            </div>
            <Footer className={footer} />
        </div>
    ) 
}

export default Layout