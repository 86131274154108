// extracted by mini-css-extract-plugin
export var headerCollapsed = "layout-module--headerCollapsed--2X3z9";
export var headerExpanded = "layout-module--headerExpanded--3Ylpd";
export var separator = "layout-module--separator--1qNcn";
export var logo = "layout-module--logo--3Mbr2";
export var slogan = "layout-module--slogan--2p-Wt";
export var container = "layout-module--container--GDXsE";
export var content = "layout-module--content--1WD_k";
export var footer = "layout-module--footer--gSkc-";
export var address = "layout-module--address--26djI";
export var footerLink = "layout-module--footerLink--3NEw0";
export var links = "layout-module--links--1XoNb";
export var heading = "layout-module--heading--1cQ3c";
export var translateElement = "layout-module--translateElement--2gY_B";