import React, { useState } from "react"
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'gatsby'
import { 
    logo, 
    slogan, 
    headerExpanded, 
    headerCollapsed, 
    separator 
} from './layout.module.scss'


const Header = () => {

    const [expanded, setExpanded] = useState(false)

    const selectItem = () => {
        setExpanded(false)
    }

    return (
        <header className={expanded ? headerExpanded : headerCollapsed}>
            <Container>
                <Navbar 
                    expanded={expanded} 
                    expand="md" 
                    onToggle={setExpanded} 
                >
                    <Link to="/">
                    <div className={logo}>
                        LATUSS  
                        <div className={slogan}>
                            Classical Inspiration
                        </div>
                    </div> 
                    </Link>
                    <Navbar.Toggle aria-controls="navbarResponsive" />
                    <Navbar.Collapse id="navbarResponsive">
                        <Nav className="text-center ml-auto" onClick={selectItem}>
                            <ul className="navbar-nav">
                                <li>
                                    <Link to="/about/" className="nav-link" activeClassName="active">
                                        <span>ABOUT</span>
                                    </Link>
                                </li>
                                <span className={separator}> • </span>
                                <li>
                                    <Link to="/lamps/" className="nav-link" activeClassName="active">
                                        <span>LAMPS</span>
                                    </Link>
                                </li>
                                <span className={separator}> • </span>
                                <li>
                                    <Link to="/contact/" className="nav-link" activeClassName="active">
                                        <span>CONTACT</span>
                                    </Link>
                                </li>
                            </ul>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </header>
    )
}

export default Header