import React from "react"
import { Link } from "gatsby"
import { footer, footerLink, address, links, heading } from './layout.module.scss'
import { Row, Col, Container } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSquare } from '@fortawesome/free-solid-svg-icons/faPhoneSquare'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'


const Footer = () => {

    let linkStyle = {
        textDecoration: 'none',
        textAlign: 'left'
    }

    /*
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement({pageLanguage: 'sl'}, 'google_translate_element')
    }

    useEffect(() => {
        var addScript = document.createElement('script');
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, [])
    */


    return (
    <footer className={footer}>
        <Container>
            <Row>
                <Col xs={12} md={6} className={address}>
                    <p className={heading}> Company </p>
                    <hr />
                    <p>
                        Latuss is a company specializing in product design for 
                        interior and exterior surroundings. We manufacture the 
                        product ourselves with the cooperation of outside partners. 
                        Our goal is to raise the level of design and technical 
                        proficiency with each new product.
                    </p>
                    <Link to="/about/" style={linkStyle} className={footerLink}>
                        <p>Read more</p>
                    </Link>
                </Col>
                <Col xs={5} md={2}>
                    <p className={heading}>Menu</p>
                    <hr />
                    <Link to="/"         style={linkStyle} className={footerLink}><p>HOME</p></Link>
                    <Link to="/about/"   style={linkStyle} className={footerLink}><p>ABOUT</p></Link>
                    <Link to="/contact/" style={linkStyle} className={footerLink}><p>CONTACT</p></Link>
                    <Link to="/lamps/"   style={linkStyle} className={footerLink}><p>LAMPS</p></Link>
                    <Link to="/contact/#faq" style={linkStyle} className={footerLink}><p>FAQ</p></Link>
                </Col>
                <Col xs={7} md={4} className={links}>
                    <p className={heading}>Get in Touch</p>
                    <hr />
                    <Link to="/contact/" style={linkStyle} className={footerLink}>
                        <p><FontAwesomeIcon icon={faEnvelope} /> Contact us</p>
                    </Link>
                    <p><FontAwesomeIcon icon={faPhoneSquare} /> +386 51 625 375</p> 
                    <p><FontAwesomeIcon icon={faFacebookSquare} /> 
                        <a href="http://www.facebook.com/Latuss.eu/" style={linkStyle} className={footerLink}>
                            <p style={{display: "inline"}}> Facebook </p>
                        </a>
                    </p>
                    <p>
                        Latuss d.o.o. 
                        <br />Slovenia      
                        <br />Vrzdenec 86 
                        <br />1354 Horjul 
                    </p>
                </Col>
            </Row>
            {/*<Row>
                <Col className={translateElement}> // layout.module.scss
                    <div id="google_translate_element"></div>
                </Col>
            </Row>
            */}
        </Container>
    </footer>
    )
}

export default Footer
