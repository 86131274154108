// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lamps-brown-js": () => import("./../../../src/pages/lamps/brown.js" /* webpackChunkName: "component---src-pages-lamps-brown-js" */),
  "component---src-pages-lamps-color-js": () => import("./../../../src/pages/lamps/color.js" /* webpackChunkName: "component---src-pages-lamps-color-js" */),
  "component---src-pages-lamps-gold-js": () => import("./../../../src/pages/lamps/gold.js" /* webpackChunkName: "component---src-pages-lamps-gold-js" */),
  "component---src-pages-lamps-index-js": () => import("./../../../src/pages/lamps/index.js" /* webpackChunkName: "component---src-pages-lamps-index-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

