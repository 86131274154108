module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Latuss","short_name":"Latuss","start_url":"/","background_color":"#f2ebe7","theme_color":"#a17257","display":"minimal-ui","icon":"src/images/favicon/favicon.png","icons":[{"src":"src/images/favicon/favicon.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicon/favicon.png","sizes":"64x64","type":"image/png"},{"src":"src/images/favicon/favicon.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicon/favicon.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"046f9ee893516ed7b9a7044c9de6c422"},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.15,"once":true,"disabled":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
